<template>
  <div class="aboutUsContainer">
    <h2>About Us</h2>
    <p>
      We are a small business specializing in laser cutting & design. Let us help you make
      custom accessories to enhance your roleplaying experiences. From custom
      name tags to terrain to character portraits, we will draw, design, cut and create with
      your guidance.
    </p>
    <p>
      For now, all our stuff is custom made and shipped from here in the heart of the
      Wasatch Mountains in Utah, so it's best to contact us via email using the button
      below.
    </p>
    <p>
      Thank you. We can't wait to get started.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
}
</script>

<style scoped>
  .aboutUsContainer {
    text-align: left;
  }

  h2 {
    width: 100%;
    text-align: left;
    color: white;
    font-family: "Arkhip", Helvetica, Arial;
    height: 6vh;
    line-height: 6vh;
    font-size: 4vh;
  }

  .aboutUsContainer > p {
    color: white;
    font-family: Helvetica, Arial;
    font-size: 2vh;
    margin-bottom: 2vh;
    line-height: 3vh;
    font-weight: 100;
  }

  @media (max-width: 1400px) {
    h2 {
      font-size: 3vh;
    }
  }
</style>
