<template>
  <div class="landingPageBanner">
    <kinesis-container class="kinesisContainer">
      <kinesis-element :strength="10">
        <img src="../assets/cd-img-1.jpg" id="image1" type="depth">
      </kinesis-element>
      <kinesis-element :strength="20">
        <img src="../assets/cd-img-2.png" id="image2" type="depth">
      </kinesis-element>
      <kinesis-element :strength="30">
        <img src="../assets/cd-img-3.png" id="image3" type="depth">
      </kinesis-element>
    </kinesis-container>
    <div class="titleContainer">
      <h1 id="title1">Dryad's Forge</h1>
      <h2 id="title2">Custom Fantasy Woodworking</h2>
    </div>
  </div>
</template>

<script>
import { KinesisContainer, KinesisElement} from 'vue-kinesis';

export default {
  name: 'LandingPageBanner',
  components: {
    KinesisContainer,
    KinesisElement
  }
}
</script>

<style scoped>
  .landingPageBanner {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .kinesisContainer {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  #image1, #image2, #image3 {
    height: 105vh;
    position: absolute;
    top: calc((105vh - 100vh) / -2);
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .titleContainer {
    width: 100%;
    position: absolute;
    margin: none;
    padding-bottom: 4vh;
    background-image: linear-gradient(transparent 0%, black 100%);
  }

  #title1, #title2 {
    color: white;
    font-family: "Arkhip", Helvetica, Arial;
    text-align: center;
  }

  #title1 {
    height: 7vh;
    line-height: 7vh;
    font-size: 7vh;
  }

  #title2 {
    height: 4vh;
    line-height: 4vh;
    font-size: 4vh;
  }

  @media (max-width: 1400px) {
    #title1 {
      font-size: 6vh;
    }
    #title2 {
      height: 4vh;
      line-height: 4vh;
      font-size: 3vh;
    }
  }
</style>
