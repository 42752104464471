<template>
  <LandingPageBanner />
  <LandingPageBody />
</template>

<script>
import LandingPageBanner from '@/components/LandingPageBanner.vue'
import LandingPageBody from '@/components/LandingPageBody.vue'

export default {
  name: 'App',
  components: {
    LandingPageBanner,
    LandingPageBody
  }
}
</script>

<style>
  @font-face {
    font-family: "Arkhip";
    src: local("Arkhip"),
    url('./fonts/Arkhip_font.ttf') format("truetype");
  }

  html {
    overflow-x: hidden;
  }

  #app {
    width: 100%;
    height: 100%;
  }
</style>
