<template>
  <div class="landingPageBody">
    <div class="topNav" v-on:click="scrollToTop">
      <img src="../assets/Dryads_Forge_Logo.png" />
    </div>
    <div class="bodyContainer">
      <Gallery />
      <div class="aboutUsAndFormContainer">
        <AboutUs />
        <ContactUsForm />
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from './Gallery.vue'
import ContactUsForm from './ContactUsForm.vue';
import AboutUs from './AboutUs.vue'

export default {
  name: 'LandingPageBody',
  components: {
    Gallery,
    AboutUs,
    ContactUsForm
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  .landingPageBody {
    background-image: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url('../assets/hazelwood.jpg');
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .topNav {
    color: white;
    font-family: "Arkhip", Helvetica, Arial;
    width: 100%;
    height: fit-content;
    background: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1vh 0px;
    cursor: pointer;
  }

  .topNav > img {
    height: 5vh;
    margin-left: 3vw;
  }

  .bodyContainer {
    height: calc(100% - 7vh);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutUsAndFormContainer {
    height: 80vh;
    width: 45vw;
    margin-left: 2vw;
  }

  @media (max-width: 1400px) {
    .landingPageBody {
      height: 160vh;
    }
    .bodyContainer {
      flex-direction: column;
    }
    .aboutUsAndFormContainer {
      width: 80vw;
      margin-left: 0vw;
    }
  }
</style>
