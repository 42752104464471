<template>
  <div class="galleryContainer">
    <img src="../assets/IMG_0169.jpg"/>
  </div>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped>
  .galleryContainer {
    width: 45vw;
    border: 3px solid white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin-right: 2vw;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }

  .galleryContainer > img {
    width: 100%
  }

  @media (max-width: 1400px) {
    .galleryContainer {
      width: 80vw;
      margin-right: 0vw;
    }
  }
</style>
