import { createApp } from "vue";
import App from "./App.vue";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VueKinesis from "vue-kinesis";

import "./css/reset.css";
import "./css/scrollBar.css";

const app = createApp(App)
  
app.component('fa', FontAwesomeIcon);
app.use(VueKinesis);

app.mount("#app");
