<template>
  <form @submit.prevent="sendEmail" class="contactUsFormContainer">
    <h2>Contact Us</h2>
    <div class="contactUsNames">
      <div class="fistName">
        <label for="firstName">First Name:</label>
        <input 
          id="firstName" 
          type="text"
          v-model="firstName"
          autocomplete="off"
          required="true"
        />
      </div>
      <div class="lastName">
        <label for="lastName">Last Name:</label>
        <input
          id="lastName"
          type="text"
          v-model="lastName"
          autocomplete="off"
          required="true"
        />
      </div>
    </div>
    <div class="contactUsRest">
      <label for="email">Email Address:</label>
      <input 
        id="email"
        type="text"
        v-model="email"
        autocomplete="off"
        required="true"
      />
      <label for="requestType">I am looking to create:</label>
      <select
        id="requestType"
        name="requestType"
        v-model="requestType"
        required="true"
      >
        <option value="Character Art">Character Art</option>
        <option value="Laser Cut Name Plaques">Laser Cut Name Plaques</option>
        <option value="Laser Cut Boxes">Laser Cut Boxes</option>
        <option value="Something Else">Something Else!</option>
      </select>
      <label for="message">Message:</label>
      <textarea id="message" type="text" v-model="message" autocomplete="off" />
      <div class="buttonContainer">
        <button
          type="submit"
          :disabled="sent || waiting"
          :class="waiting ? 'success' : 'other'"
        >
          <fa v-if="waiting" :icon="['fas', 'check']" />
          <fa v-if="!waiting" :icon="['fas', 'paper-plane']" />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faCheck, faPaperPlane)

export default {
  name: 'ContactUsForm',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      requestType: '',
      message: '',
      waiting: false,
      sent: false,
    }
  },
  methods: { 
    async sendEmail() {
      this.waiting = true

      const url = `${process.env.VUE_APP_BASE_URL}/email`
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        requestType: this.requestType,
        body: this.message,
        messages: {}
      }

      console.log(process.env.VUE_APP_BASE_URL)
      console.log(url)
      const promise = axios.post(url, data)

      await promise
      this.waiting = false
      this.sent = true
    },
  }
}
</script>

<style scoped>
  .contactUsFormContainer, .contactUsRest, .fistName, .lastName {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  h2 {
    width: 100%;
    text-align: left;
    color: white;
    font-family: "Arkhip", Helvetica, Arial;
    height: 6vh;
    line-height: 6vh;
    font-size: 4vh;
  }
  .fistName {
    margin-right: 1vh;
  }
  .contactUsNames {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contactUsNames > input:nth-child(1) {
    margin-right: 1vh;
  }
  .contactUsRest > textarea {
    width: 100%;
    height: 15vh;
    font-family: Arial, Helvetica,;
    padding: 3px 10px;
    margin: none;
    border: none;
    font-size: 1.25vh;
    margin-bottom: 1vh;
    border: 3px solid white;
    border-radius: 3px;
    resize: none;
  }
  label {
    width: 100%;
    text-align: left;
    margin-bottom: .5vh;
    color: white;
    font-family: "Arkhip", Helvetica, Arial;
    font-size: 1vh;
  }
  input, select {
    width: 100%;
    font-family: Arial, Helvetica,;
    height: 2.5vh;
    padding: 3px 10px;
    margin: none;
    border: none;
    font-size: 1.25vh;
    margin-bottom: 1vh;
    border: 3px solid white;
    border-radius: 3px;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .buttonContainer > button {
    width: 100px;
    height: 4vh;
    font-size: 2vh;
    border-radius: 1vh;
    border: none;
  }

  .success {
    background-color: #5cb85c;
    color: white;
  }

  .other {
    background-color: white;
    color: black;
  }

  @media (max-width: 1400px) {
    h2 {
      font-size: 3vh;
    }
  }
</style>
